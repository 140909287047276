import { signOut } from 'next-auth/react'

type CustomOptions = RequestInit & {
  baseUrl?: string
  body?: any
  headers?: Record<string, string>
  type?: string
}

type AuthenErrorPayload = {
  code: string
  message: string
  errors: {
    filed: string
    message: string
  }[]
}

export class HttpError extends Error {
  status: number
  payload: any

  constructor({ status, payload }: { status: number; payload: any }) {
    super('HTTP Error')
    this.status = status
    this.payload = payload
  }
}

export class AuthenError extends HttpError {
  status: 401
  payload: AuthenErrorPayload

  constructor({
    status,
    payload,
  }: {
    status: 401
    payload: AuthenErrorPayload
  }) {
    super({ status, payload })
    this.status = status
    this.payload = payload
  }
}

class SessionToken {
  private token = ''

  get value() {
    return this.token
  }

  set value(token: string) {
    if (typeof window === 'undefined') {
      throw new Error('Cannot set token on server side')
    }
    this.token = token
  }
}

export const sessionToken = new SessionToken()

const request = async <Response>(
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  url: string,
  options?: CustomOptions | undefined
) => {
  const body = options?.body ? JSON.stringify(options.body) : undefined
  const bodyFile = options?.body ? options.body : undefined
  const baseHeaders =
    options?.type === 'file'
      ? {
          Authorization: `Bearer ${sessionToken.value}`,
        }
      : ({
          Authorization: `Bearer ${sessionToken.value}`,
          'Content-Type': 'application/json',
        } as Record<string, string>)

  // Nếu không truyền baseUrl thì lấy từ biến môi trường
  const baseUrl =
    options?.baseUrl === undefined
      ? process.env.NEXT_PUBLIC_API_URL
      : options.baseUrl

  const fullUrl = url.startsWith('/') ? `${baseUrl}${url}` : `${baseUrl}/${url}`

  let init = {
    ...options,
    headers: {
      ...baseHeaders,
      ...options?.headers,
    },
    bodyFile,
    method,
  }

  if (options?.type !== 'file') {
    init = {
      ...init,
      body,
    }
  }

  try {
    const res = await fetch(fullUrl, init)

    if (res.status === 200) {
      const payload: Response = await res.json()

      return {
        status: res.status,
        payload,
      }
    } else {
      if (res.status === 401) {
        await signOut()
        throw new AuthenError({
          status: res.status,
          payload: {
            code: '401',
            message: 'Unauthorized',
            errors: [],
          },
        })
      } else {
        let payload = {} as Response
        throw new HttpError({ status: res.status, payload })
      }
    }
  } catch (error) {
    let payload = {} as Response
    throw new HttpError({ status: 404, payload })
  }
}

const http = {
  get: <Response>(
    url: string,
    options?: Omit<CustomOptions, 'body'> | undefined
  ) => request<Response>('GET', url, options),
  post: <Response>(
    url: string,
    body: any,
    options?: Omit<CustomOptions, 'body'> | undefined
  ) => request<Response>('POST', url, { body, ...options }),
  put: <Response>(
    url: string,
    body: any,
    options?: Omit<CustomOptions, 'body'> | undefined
  ) => request<Response>('PUT', url, { body, ...options }),
  delete: <Response>(
    url: string,
    body: any,
    options?: Omit<CustomOptions, 'body'> | undefined
  ) => request<Response>('DELETE', url, { body, ...options }),
}

export default http
