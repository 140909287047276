export const VIEW_FILTER = {
  GRID: 'GRID',
  MAP: 'MAP',
}

export const TERM_TYPE = {
  LONG: 'LONG_TERM',
  SHORT: 'SHORT_TERM',
}

export const BOOKING_STATUS = {
  WAIT_PAYMENT: 'WAIT_PAYMENT',
  WAIT_CONFIRM: 'WAIT_CONFIRM',
  PAID: 'PAID',
  RECEIVED: 'RECEIVED',
  FINISH: 'FINISH',
  CANCEL: 'CANCEL',
}

export const ListButton = [
  { name: 'Tất cả', name_en: 'All', status: '' },
  {
    name: 'Chờ thanh toán',
    name_en: 'Awaiting payment',
    status: BOOKING_STATUS.WAIT_PAYMENT,
  },
  {
    name: 'Chờ xác nhận',
    name_en: 'Awaiting confirmation',
    status: BOOKING_STATUS.WAIT_CONFIRM,
  },
  { name: 'Đã thanh toán', name_en: 'Paid', status: BOOKING_STATUS.PAID },
  { name: 'Đã nhận xe', name_en: 'Picked up', status: BOOKING_STATUS.RECEIVED },
  { name: 'Hoàn thành', name_en: 'Completed', status: BOOKING_STATUS.FINISH },
  { name: 'Đã hủy', name_en: 'Canceled', status: BOOKING_STATUS.CANCEL },
]

export const ListRole = {
  ADMIN: 'ADMIN',
  OWNER: 'OWNER',
  CUSTOMER: 'CUSTOMER',
}

export const ListStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
}

export const FeatureType = {
  OPTION: 'OPTION',
  BOOLEAN: 'BOOLEAN',
}

export const RangeTime = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
]
